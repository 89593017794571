import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../utils/theme'
import { useTranslation } from 'react-i18next-new'
import useRoutes from '../../../hooks/useRoutes'
import { Divider, SmallPageSection } from '../../reusable_components/layout/PageComponents'
import LanguageSwitcher from './LanguageSwitcher'
import { isMarketplace } from '../../../utils/applicationHelper'
import { Link } from 'react-router-dom'
import ConditionalLink from '../../reusable_components/other/ConditionalLink'

const FooterWrapper = styled.div`
	padding: ${theme.paddings.large} 0;
`
const Columns = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: ${theme.margins.small};
`

const Footer = ({ footerLinks }) => {
	const { t, i18n } = useTranslation()
	const { categories, marketplaces } = footerLinks
	const routes = useRoutes()
	return (
		<FooterWrapper id="pageFooter">
			<Divider />
			<SmallPageSection>
				<Columns>
					{categories?.length > 0 && (
						<div>
							<b>{t('copy:footer.topCategories')}</b>
							{categories.map((category, key) => (
								<div key={key}>
									<Link to={category.path}>{category.title}</Link>
								</div>
							))}
						</div>
					)}
					{isMarketplace() && marketplaces?.length > 0 && (
						<div>
							<b>{t('copy:footer.marketplaces')}</b>
							{marketplaces.map((marketplace, key) => (
								<div key={key}>
									<a href={marketplace.url}>{marketplace.title}</a>
								</div>
							))}
						</div>
					)}
					<div>
						<b>{t('copy:footer.about')}</b>
						<div>
							<a href="https://help.ejbla.com/">{t('copy:footer.faq')}</a>
						</div>
						<div>
							<a href="mailto:help@ejbla.com" target="_blank" rel="noopener">
								{t('copy:footer.contact')}
							</a>
						</div>
					</div>
					<div>
						<b>{t('copy:footer.creator')}</b>
						<div>
							<ConditionalLink to={routes.static.ejblaStartPage[isMarketplace() ? 'url' : 'path']}>
								{t('copy:footer.hostWithEjbla')}
							</ConditionalLink>
						</div>
						<div>
							<a href="https://organizerhelp.ejbla.com/">{t('copy:footer.helpCenterExperts')}</a>
						</div>
						<div>
							<ConditionalLink to={routes.static.pricing[isMarketplace() ? 'url' : 'path']}>
								{t('copy:footer.price')}
							</ConditionalLink>
						</div>
					</div>
					<div>
						<b>{t('copy:footer.documents')}</b>
						<div>
							<a href={routes.custom.integrityPolicy.url} target="_blank" rel="nofollow noreferrer noopener">
								{t('common:documents.integrityPolicy')}
							</a>
						</div>
						<div>
							<a href={routes.custom.termsOfUse.url} target="_blank" rel="nofollow noreferrer noopener">
								{t('common:documents.termsOfUse')}
							</a>
						</div>
					</div>
				</Columns>
			</SmallPageSection>
			<SmallPageSection>
				<LanguageSwitcher color={theme.colors.black} />
			</SmallPageSection>
			<div>
				{new Date().getFullYear()} {t('copy:footer.copyRight')}
			</div>
		</FooterWrapper>
	)
}

export default Footer
